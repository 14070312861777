import React from "react";
import { Title, Description } from "./Summary.styles";

interface SkillsProps {
  data: string;
}

const Summary = ({ data }: SkillsProps) => {
  if (!data) {
    return null;
  }
  return (
    <div>
      <Title>Summary</Title>
      <Description>{data}</Description>
    </div>
  );
};

export default Summary;
