import * as contentful from "contentful";

const contentfulConfig = {
  environment: "master",
  space: "fdvlbuxr4258",
  accessToken: "1oxTlT-fWYGwz3Si6tcGq7m9xsvhVt8TKUtQj147Ysk",
};

const client = contentful.createClient({
  ...contentfulConfig,
});

const extractField = ({ fields, sys }) => ({ ...fields, id: sys.id });

const extractFields = ({ items = [] }) => {
  return items.map(extractField);
};

const extractFirst = (array) => {
  return array[0];
};

const extractFieldsAndSys = ({ items = [] }) => {
  return items.map(extractField);
};

export const trainingApi = client
  .getEntries({ content_type: "training" })
  .then(extractFields);

// eslint-disable-next-line no-unused-vars
const logFn = (label) => (response) => {
  console.log(label, response);
  return response;
};

export const overviewApi = client
  .getEntries({ content_type: "overview" })
  .then(extractFields)
  .then(extractFirst);

export const experienceApi = client
  .getEntries({ content_type: "experience" })
  .then(extractFields);
