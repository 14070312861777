import React from "react";
import { Description, Title, MainContainer } from "./Skills.styles";
import { overviewApi } from "../../../api/contentfulApi";

console.log(overviewApi);

interface SkillsProps {
  data: string[];
}

const Skills = ({ data = [] }: SkillsProps) => {
  if (!data) {
    return null;
  }
  console.log("skillsData", data);
  return (
    <div>
      <Title>Skills</Title>
      <ul>
        {data.map((item, index) => {
          return (
            <MainContainer key={index}>
              <li>
                <Description>{item}</Description>
              </li>
            </MainContainer>
          );
        })}
      </ul>
    </div>
  );
};

export default Skills;
