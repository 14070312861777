import { Image, Divider } from "antd";
import styled from "styled-components";

export const Border = styled(Divider)`
  background-color: #161c23;
  height: 16px;
`;

export const Picture = styled(Image)`
  overflow: hidden;
  /* min-width: 50%; */
  background-color: #161c23;
  min-height: 400px;
`;
export const NameContainer = styled.div`
  width: 60%;
  height: 400px;
  background-color: #161c23;
  color: white;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin-right: 2rem;
`;
export const Text = styled.div`
  font-family: "Glory", sans-serif;
  font-size: 20px;
  font-weight: 500px;
`;
export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const ImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const Title = styled.div`
  font-family: "Glory", sans-serif;
  font-style: Bold;
  font-size: 64px;
  line-height: 72px;
  font-weight: 500;
  text-align: Center;
  vertical-align: Top;
`;
