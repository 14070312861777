import styled from "styled-components";
import { Divider } from "antd";

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5rem;
  margin-top: 2rem;
  margin-bottom: 5rem;
  margin-bottom: 5rem;
`;

export const Border = styled(Divider)`
  background-color: #161c23;
  height: 4px;
`;
