import { Image } from "antd";
import styled from "styled-components";

export const Title = styled.div`
  font-family: "Glory", sans-serif;
  font-size: 64px;
  line-height: 72px;
  font-weight: 600;
  text-align: Left;
  vertical-align: Top;
`;
export const Description = styled.div`
  font-family: "Glory", sans-serif;
  padding: 10px;
  font-size: 20px;
  font-weight: 500px;
  vertical-align: Top;
  text-align: Left;
`;
