import { Divider } from "antd";
import React from "react";
import { Title, Text, MainContainer, CourseLink } from "./Training.styles";
// import { trainingApi } from "../../../api/contentfulApi";

const MockData = {
  "1": {
    courseLink: "RxJS Reactive Programming - BrieBug (2019)",
    CourseLink:
      "https://www.udemy.com/course/the-ultimate-drawing-course-beginner-to-advanced/",
    courseDescription:
      "This was a very interesting course that has taght me a lot of absolutely useless things that i highly doubt i will ever use in my life but at least they gave me a shiny badge at the end of it so i could show off to my stupid friends and people like you",
  },
  "2": {
    courseLinkName: "Java spring boot course - Udemy (2019)",
    CourseLink: "https://www.udemy.com/course/the-art-and-science-of-drawing/",
    courseDescription:
      "This was a very interesting course that has taght me a lot of absolutely useless things that i highly doubt i will ever use in my life but at least they gave me a shiny badge at the end of it so i could show off to my stupid friends and people like you",
  },
  "3": {
    courseLinkName: "React course - Udemy (2019)",
    CourseLink: "https://www.udemy.com/course/agile-crash-course/",
    courseDescription:
      "This was a very interesting course that has taght me a lot of absolutely useless things that i highly doubt i will ever use in my life but at least they gave me a shiny badge at the end of it so i could show off to my stupid friends and people like you",
  },
};
interface TrainingProps {
  data: any;
}
const Training = ({ data }: TrainingProps) => {
  if (!data) {
    return null;
  }
  return (
    <MainContainer>
      <Title>Training</Title>
      {data.map((item: any, index: number) => {
        return (
          <div key={index}>
            <CourseLink key={index}>{item.courseLink}</CourseLink>
            <Text>{item.courseOverview}</Text>
          </div>
        );
      })}
    </MainContainer>
  );
};

export default Training;
