import React from "react";
import Header from "../../common/components/Header/Header";
import Summary from "../../common/components/Summary/Summary";
import { MainContainer, Border } from "./Home.styles";
import Skills from "../../common/components/Skills/Skills";
import Experience from "../../common/components/Experience/Experience";
import Training from "../../common/components/Training/Training";
import useContentful from "../../useContentful";
import Personal from "../../common/components/Personal/Personal";

const Home = () => {
  const { overviewList, experienceList, trainingList } = useContentful();

  if (!overviewList) {
    return null;
  }

  console.log("overview", overviewList);

  return (
    <MainContainer>
      <div style={{ maxWidth: "50%", minWidth: "700px" }}>
        <Header />
        <Border />
        <Summary data={overviewList.summary} />
        <Border />
        <Skills data={overviewList.skills} />
        <Border />
        <Experience data={experienceList} />
        <Border />
        <Training data={trainingList} />
        <Border />
        <Personal data={overviewList} />
      </div>
    </MainContainer>
  );
};

export default Home;
