import React from "react";
import {
  Title,
  MainContainer,
  DetailsContainer,
  Conatiner1,
  RightConatiner,
  LeftContainer,
  DateConatiner,
  Text,
  JobConatiner,
  Button,
} from "./Experience.styles";
import { format } from "date-fns";

interface ExperienceProps {
  data: any;
}
const Skills = ({ data }: ExperienceProps) => {
  console.log("data", data);
  if (!data) {
    return null;
  }
  return (
    <MainContainer>
      <Title>Work Experience</Title>
      {data.map((item: any) => {
        console.log("item", item);
        return (
          <DetailsContainer>
            <Conatiner1>
              <RightConatiner>
                <Text>{item.role}</Text>
                <Text>{`${item.companyName}/${item.projectName}`}</Text>
              </RightConatiner>
              <LeftContainer>
                <DateConatiner>
                  <div>
                    {" "}
                    {item.startDate &&
                      format(new Date(item.startDate), "MMM y")}
                  </div>
                  -
                  <div>
                    {" "}
                    {item.endDate && format(new Date(item.endDate), "MMM y")}
                  </div>
                </DateConatiner>
                <Button>Open App</Button>
              </LeftContainer>
            </Conatiner1>
            <ul>
              {item.details.map((item: any) => {
                return (
                  <JobConatiner>
                    <li>
                      <Text>{item}</Text>
                    </li>
                  </JobConatiner>
                );
              })}
            </ul>
          </DetailsContainer>
        );
      })}
    </MainContainer>
  );
};

export default Skills;
