import styled from "styled-components";
import { PlusCircleFilled } from "@ant-design/icons";

export const Title = styled.div`
  font-family: Glory, sans-serif;
  font-size: 64px;
  line-height: 72px;
  font-weight: 600;
  text-align: Left;
  vertical-align: Top;
`;
export const Text = styled.div`
  font-family: Glory, sans-serif;
  padding: 10px;
  font-size: 20px;
  font-weight: 400;
  text-align: left;
  color: #767676;
`;
export const MainContainer = styled.div`
  font-family: Glory, sans-serif;
  display: flex;
  flex-direction: column;
  text-align: left;
`;

export const CourseLink = styled.div`
  font-family: Glory, sans-serif;
  font-style: regular;
  font-size: 20px;
  padding: 10px;
  cursor: pointer;
  font-weight: 400;
  text-align: left;
  text-decoration: underline;
`;
export const CourseDescription = styled.div``;
