import styled from "styled-components";
import { Button as AntdButton } from "antd";

export const Button = styled(AntdButton)`
  /* max-width: "152.09px"; */
  max-width: 30%;
  min-width: 30%;
  background-color: #161c23;
  color: white;
`;

export const Title = styled.div`
  font-family: "Glory", sans-serif;
  font-size: 64px;
  line-height: 72px;
  font-weight: 600;
  text-align: Left;
  vertical-align: Top;
`;
export const Text = styled.div`
  font-family: "Glory", sans-serif;
  padding: 10px;
  font-size: 20px;
  font-weight: 400;
  text-align: left;
`;
export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
`;
export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;
export const Conatiner1 = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;
export const RightConatiner = styled.div`
  text-align: left;
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const LeftContainer = styled.div`
  text-align: right;
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const DateConatiner = styled.div`
  display: flex;
  flex-direction: row;
`;

export const JobConatiner = styled.div`
  text-align: left;
`;
