import { Divider } from "antd";
import React from "react";
import { Title, Text } from "./Personal.styles";

interface PersonalProps {
  data: any;
}

const Personal = ({ data }: PersonalProps) => {
  if (!data) {
    return null;
  }
  console.log("data", data);
  return (
    <div>
      <Title>Hobbies & Interests</Title>
      <Text>{data.hobbies}</Text>
    </div>
  );
};

export default Personal;
