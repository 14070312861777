import styled from "styled-components";
import { PlusCircleFilled } from "@ant-design/icons";

export const Title = styled.div`
  font-family: "Glory", sans-serif;
  font-size: 64px;
  line-height: 72px;
  font-weight: 600;
  text-align: Left;
  vertical-align: Top;
`;
export const Description = styled.div`
  max-width: 750px;
  font-family: Glory, sans-serif;
  padding: 10px;
  font-size: 20px;
  font-weight: 500px;
  text-align: Left;
  justify-content: flex-start;
  vertical-align: Top;
`;
export const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;
