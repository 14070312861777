import React from "react";
import {
  Picture,
  MainContainer,
  ImageContainer,
  NameContainer,
  Title,
  Text,
  Border,
} from "./Header.styles";
import { CloseCircleFilled } from "@ant-design/icons";

const Header = () => {
  return (
    <MainContainer>
      <ImageContainer>
        <NameContainer>
          <CloseCircleFilled spin={true} />
          <Title>Tristan Clovis</Title>
        </NameContainer>
        <Picture src="https://www.clipartmax.com/png/full/43-430769_beakers-miller%E2%80%93urey-experiment.png" />
      </ImageContainer>
      <Border />
      <Text>131 Elm High Road, Wisbech, PE14 0DP</Text>
      <Text>Email:tristandominicclovis@gmail.com</Text>
    </MainContainer>
  );
};

export default Header;
