import styled from "styled-components";

export const Title = styled.div`
  font-family: "Glory", sans-serif;
  font-size: 50px;
  line-height: 72px;
  font-weight: 600;
  text-align: Left;
  vertical-align: Top;
`;
export const Text = styled.div`
  font-family: "Glory", sans-serif;
  padding: 10px;
  font-size: 20px;
  font-weight: 400;
  text-align: left;
`;
export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-bottom: "5rem";
`;

export const CourseLink = styled.div``;
export const CourseDescription = styled.div``;
