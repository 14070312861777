import { useEffect, useState } from "react";
import { Interface } from "readline";
import { experienceApi, overviewApi, trainingApi } from "./api/contentfulApi";

interface Overview {
  skills: string[];
  summary: string;
}

const useContentful = () => {
  const [trainingList, setTrainingList] = useState<any>();
  const [overviewList, setOverviewList] = useState<Overview>();
  const [experienceList, setExperienceList] = useState<any>();

  useEffect(() => {
    overviewApi.then((response) => {
      setOverviewList(response);
    });
    experienceApi.then((response) => {
      setExperienceList(response ?? []);
    });
    trainingApi.then((response) => {
      setTrainingList(response ?? []);
    });
  }, []);

  return {
    trainingList,
    overviewList,
    experienceList,
  };
};

export default useContentful;
